import {FC, ReactNode, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEllipsis, faEllipsisVertical, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

export interface SelectBoxOption {
  value: string
  label: string
  leading?: ReactNode
}
export const SelectBox: FC<{options: SelectBoxOption[], mode: "single"|"multi", value: string[], onChange: (val: string[]) => void, contextMenuBuilder?: (option: SelectBoxOption, close: () => void) => ReactNode}> = props => {
  const optionStyle = 'block w-full py-2 cursor-pointer pr-3 text-left leading-tight'
  const selectedOptionStyle = 'block w-full py-2 cursor-pointer bg-brand-600 text-white pr-3 text-left font-medium leading-tight'
  const [search, setSearch] = useState('')
  const filteredOptions = props.options.filter(option => props.value.includes(option.value) || option.label.toLowerCase().includes(search.toLowerCase()))
  const [openContextMenu, setOpenContextMenu] = useState<SelectBoxOption['value'] | null>(null)
  return <div className={"border border-slate-300 rounded h-72 flex flex-col items-stretch"}>
    <div className={"flex items-center"}>
      <input type="text" className={"flex-1 px-3 h-8 bg-transparent border-b border-slate-300"} placeholder={'Zoeken...'} value={search} onChange={e => setSearch(e.target.value)} />
      {search.length > 0 && <button className={'h-6 w-6 text-red-700 flex items-center justify-center hover:bg-red-200 rounded m-1'} onClick={() => setSearch('')}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>}
    </div>
    <div className={'overflow-y-scroll pb-16 flex-1'}>
      <button className={`px-3 ${(props.value.length === 0 || props.value.length === props.options.length) ? selectedOptionStyle : optionStyle}`} onClick={() => props.onChange([])}>Alle ({props.options.length})</button>
      {filteredOptions.map(option => <div key={option.value}
                                          className={((props.value.includes(option.value) || openContextMenu === option.value) ? selectedOptionStyle : optionStyle) + ' flex items-center'}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            props.onChange([option.value])
                                          }}>
        {/* Check box */}
        {props.mode === 'multi' && <>
          <button
            className={`px-1 py-1 ml-2 rounded ${props.value.includes(option.value) ? 'hover:bg-brand-700' : 'hover:bg-slate-200'}`}
            onClick={(e) => {
              e.stopPropagation()
              props.onChange(props.value.includes(option.value) ? props.value.filter(x => x != option.value) : [...props.value, option.value])
            }}>
            <div
              className={`h-4 w-4 border-2 rounded flex items-center justify-center ${props.value.includes(option.value) ? 'border-white bg-white text-brand-700' : 'border-brand-700'}`}>
              <FontAwesomeIcon icon={faCheck} className={'h-3 w-3'}
                               style={{visibility: props.value.includes(option.value) ? 'visible' : 'hidden'}}/>
            </div>
          </button>
        </>}

        {/* Leading*/}
        {option.leading !== undefined ? <>{option.leading}</> : <></>}

        {/* Content*/}
        <div className={'pl-3 flex-1 text-left'}>{option.label}</div>

        {/* Context menu */}
        {props.contextMenuBuilder !== undefined && <button
          className={`px-1 py-1 ml-1 rounded relative ${props.value.includes(option.value) ? 'hover:bg-brand-700' : 'hover:bg-slate-200'}`}
          onClick={(e) => {
            e.stopPropagation()
            setOpenContextMenu(option.value)
          }}>
          <FontAwesomeIcon icon={faEllipsisVertical} className={'h-4 w-4'}/>
          {openContextMenu === option.value ? <>
            <div className={'absolute right-0 top-0 bg-white rounded border-2 border-slate-200 w-40 z-20 shadow-lg'}>
              {props.contextMenuBuilder(option, () => setOpenContextMenu(null))}
            </div>
          </> : <></>}
          {openContextMenu !== null ? <>
            <div className={'fixed inset-0 z-10 bg-[#00000003]'} onClick={(e) => {
              e.stopPropagation()
              setOpenContextMenu(null)
            }}></div>
          </> : <></>}
        </button>}
      </div>)}
    </div>
  </div>
}
export const SelectBoxSkeleton: FC<{}> = props => {
  return <div className={"border border-slate-300 bg-slate-300 animate-pulse rounded h-72 flex flex-col items-stretch"}></div>
}
