import {useCallback} from "react";
import {getToken} from "../auth/AuthProvider";
import config from "../config";
import {Assessor, Company, Exam, ExamParticipant, Project, ProposedChange, QuestionSet} from "./dto";

const API_BASE_URL = config.apiBaseUrl

export const useApiCall = () => {
  const checkForValidationErrors = async (response: Response): Promise<void> => {
    if (response.status === 422) {
      const json = await response.json()
      if ("message" in json && "errors" in json && typeof json.errors === "object") {
        throw new ValidationError(json.message, json.errors)
      }
      if ("message" in json) {
        throw new ValidationError(json.message, {'message': [json.message]})
      }
    }
  }

  const get = useCallback(async function<T>(url: string) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      }
    })
    await checkForValidationErrors(response)
    return await response.json() as {data: T}
  }, [])
  const post = useCallback(async function<T>(url: string, body: object) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(body)
    })
    await checkForValidationErrors(response)
    return await response.json() as {data: T}
  }, [])
  const postRaw = useCallback(async function<T>(url: string, body: object) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(body)
    })
    await checkForValidationErrors(response)
    return response
  }, [])
  const postFile = useCallback(async function<T>(url: string, name: string, file: File) {
    const formData = new FormData()
    formData.append(name, file)
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getToken()}`
      },
      body: formData
    })
    await checkForValidationErrors(response)
    return response
  }, [])
  const put = useCallback(async function<T>(url: string, body: object) {
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      },
      body: JSON.stringify(body)
    })
    await checkForValidationErrors(response)
    return await response.json() as {data: T}
  }, [])
  const del = useCallback(async function<T>(url: string) {
    console.log('sending delete call')
    const response = await fetch(`${API_BASE_URL}/${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${getToken()}`
      }
    })
    console.log('checking for validation errors')
    await checkForValidationErrors(response)
  }, [])

  return {
    // Companies
    async listCompanies(){
      const response = await get<object[]>('companies')
      return response.data.map(json => Company.fromJson(json))
    },
    // Projects
    async listProjects(){
      const response = await get<object[]>('projects')
      return response.data.map(json => Project.fromJson(json))
    },
    // QuestionSets
    async listQuestionSets(){
      const response = await get<object[]>('question_sets')
      return response.data.map(json => QuestionSet.fromJson(json))
    },
    // Assessors
    async listAssessors(){
      const response = await get<object[]>('assessors')
      return response.data.map(json => Assessor.fromJson(json))
    },
    async updateAssessor(assessor: Assessor, fields: Partial<Assessor>){
      await put<void>(`assessors/${assessor.id}`, {
        first_name: fields.first_name ?? assessor.first_name,
        last_name: fields.last_name ?? assessor.last_name,
        options: fields.options ? {...assessor.options, ...fields.options} : assessor.options,
      })
    },
    async destroyAssessor(assessorId: string) {
      await del<void>(`assessors/${assessorId}`)
    },
    // Exams
    async listExams(){
      const response = await get<object[]>('exams?withArchived=true')
      return response.data.map(json => Exam.fromJson(json))
    },
    async archiveExam(examId: string){
      await del<void>(`exams/${examId}`)
    },
    async destroyExam(examId: string) {
      await del<void>(`exams/${examId}/delete`)
    },
    async revertExam(examId: string) {
      await post<void>(`exams/${examId}/revert`, {})
    },
    async updateExam(examId: string, data: object) {
      await put<void>(`exams/${examId}`, data)
    },
    // ExamParticipants
    async listExamParticipants(examId: string) {
      const response = await get<object[]>(`exams/${examId}/participants`)
      return response.data.map(json => ExamParticipant.fromJson(json))
    },
    async listAllParticipants() {
      const response = await get<object[]>(`participants`)
      return response.data.map(json => ExamParticipant.fromJson(json))
    },
    getExamExportUrl(examId: string, raw: boolean){
      return `${API_BASE_URL}/exams/${examId}/results/export?raw=${raw?'true':'false'}&access_token=${getToken()}`
    },
    getExamReportUrl(examId: string, contractor?: string){
      return `${API_BASE_URL}/exams/${examId}/results/report?${contractor ? `contractor=${encodeURIComponent(contractor)}&` : ''}access_token=${getToken()}`
    },
    getExportEmptyTemplateUrl(){
      return `${API_BASE_URL}/export/template_leeg.csv?access_token=${getToken()}`
    },
    getExportConceptTemplateUrl(){
      return `${API_BASE_URL}/export/template_concept.csv?access_token=${getToken()}`
    },
    async importCsvTest(content: string) {
      const response = await post<object[]>('import/test', {content})
      return response.data.map(json => ProposedChange.fromJson(json))
    },
    async importCsvApply(content: string){
      const response = await post<object[]>('import/apply', {content})
      return response.data.map(json => ProposedChange.fromJson(json))
    },
    async exportSelectionExcelTestReport(examIds: string[], contractors: string[]|null, subContractors: string[]|null){
      const body: {[key: string]: any} = {'exam_ids': examIds}
      if (contractors) body['contractors'] = contractors
      if (subContractors) body['subcontractors'] = subContractors
      const response = await postRaw<object[]>('export-selection/test-report-excel', body)
      return await response.blob()
    },
    async exportSelectionAdminDashboardPdf(examIds: string[], groupContractors: string[], contractors: string[]|null, subContractors: string[]|null){
      const body: {[key: string]: any} = {'exam_ids': examIds, 'group_contractors': groupContractors}
      if (contractors) body['contractors'] = contractors
      if (subContractors) body['subcontractors'] = subContractors
      const response = await postRaw<object[]>('export-selection/admin-dashboard', body)
      return await response.blob()
    },
    async updateCompany(companyId: string, name: string) {
      await put<void>(`companies/${companyId}`, {name})
    },
    async archiveCompany(companyId: string) {
      await del<void>(`companies/${companyId}`)
    },
    async updateProject(companyId: string, projectId: string, name: string) {
      await put<void>(`companies/${companyId}/projects/${projectId}`, {name})
    },
    async archiveProject(companyId: string, projectId: string) {
      await del<void>(`companies/${companyId}/projects/${projectId}`)
    },
    async updateCompanyLogo(companyId: string, logo: File) {
      await postFile<void>(`companies/${companyId}/logo`, 'logo', logo)
    },
    async deleteCompanyLogo(companyId: string) {
      await del<void>(`companies/${companyId}/logo`)
    },
  }
}

export class ValidationError extends Error {
  constructor(public readonly message: string, public readonly errors: { [key: string]: string[] }) {
    super(message);
  }
}
