import React, {FC, useEffect, useState} from "react";
import {Company} from "../api/dto";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Input} from "../components/form/Input";
import {useApiCall, ValidationError} from "../api/api";
import {useRefresh} from "../components/RefreshController";

export const AddCompanyLogoModal: FC<{company: Company}> = props => {
  const {updateCompanyLogo, deleteCompanyLogo} = useApiCall()
  const refresh = useRefresh()
  const [files, setFiles] = useState<File[]>([] as File[])
  const [errors, setErrors] = useState<string[]>([])
  const save = async () => {
    const file = files[0]
    if (!file) return
    await updateCompanyLogo(props.company.id, file)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
    refresh()
  }
  useEffect(() => {
    Promise.all(files.map(file => file.arrayBuffer())).then(buffers => {
      if (buffers.some(b => b.byteLength > 2 * 1024 * 1024)) {
        setErrors(['Bestand is te groot'])
        return
      } else {
        setErrors([])
      }
    })
  }, [files]);
  return <div>
    <label className={`flex flex-col text-sm font-medium text-brand-900 w-full`}>
      <input
        type={'file'}
        multiple={false}
        accept={'image/jpeg,image/png,image/jpg,image/gif,image/svg+xml'}
        className={`border-2 border-slate-200 outline-brand-700 text-black rounded text-base font-normal px-2 py-1 mt-1 min-h-10 w-full`}
        onChange={(e) => setFiles(e.target.files ? Array.from(e.target.files) : [])}
      />
    </label>
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <div className={'bg-amber-100 mt-4 border-2 border-amber-200 text-amber-900 mb-4 text-sm rounded-lg px-4 py-3'}>
      <h2 className={"font-bold"}>Let op</h2>
      Logo's moeten in jpeg,png,jpg,gif of svg formaat zijn, en mogen maximaal 2MB zijn.
    </div>

    <ModalFooter text={'Opslaan'} onSubmit={save}/>
  </div>
}
