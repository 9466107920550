import React, {FC, ReactNode} from "react";

interface HeaderProps {
  children: ReactNode
}

export const PageHeader: FC<HeaderProps> = props => {
  return <h1 className={"h-12 flex items-center text-2xl mb-6 font-bold"}>
    {props.children}
  </h1>
}