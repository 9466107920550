import React, {FC} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {useApiCall} from "../api/api";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Company} from "../api/dto";

export const ArchiveCompanyModal: FC<{company: Company }> = (props) => {
  const {archiveCompany} = useApiCall()
  const reload = useRefresh()

  const confirm = async () => {
    await archiveCompany(props.company.id)
    reload()
  }

  return <>
    <p>
      <strong>Let op!</strong> Als je dit bedrijf <strong>{props.company.name}</strong> archiveert worden ook alle onderliggende projecten gearchiveerd.
    </p>
    <ModalFooter icon={faTrash} text={"Verwijderen"} onSubmit={confirm}/>
  </>
}
