import React, {FC, useCallback, useState} from "react";
import {useApiCall} from "./api";
import {useRefreshEffect} from "../components/RefreshController";
import {Assessor, Company, Exam, ExamParticipant, Project, QuestionSet} from "./dto";


interface APIContextType {
  companies: Company[]
  projects: Project[]
  questionSets: QuestionSet[]
  assessors: Assessor[]
  exams: Exam[]
  participants: ExamParticipant[]
  loading: boolean
  refreshAll: () => Promise<void>
  refresh: {
    companies: (silent: boolean) => Promise<void>
    projects: (silent: boolean) => Promise<void>
    questionSets: (silent: boolean) => Promise<void>
    exams: (silent: boolean) => Promise<void>
    assessors: (silent: boolean) => Promise<void>
    participants: (silent: boolean) => Promise<void>
  }
}
export const APIContext = React.createContext<APIContextType>({} as APIContextType)

export const APIContextProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const {listCompanies, listProjects, listQuestionSets, listExams, listAssessors, listAllParticipants} = useApiCall()
  const companies = useFetchedResource(() => listCompanies())
  const projects = useFetchedResource(() => listProjects())
  const questionSets = useFetchedResource(() => listQuestionSets())
  const exams = useFetchedResource(() => listExams())
  const assessors = useFetchedResource(() => listAssessors())
  const participants = useFetchedResource(() => listAllParticipants())

  const refreshAll = useCallback(async () => {
    await Promise.all([
      companies.reload(undefined),
      projects.reload(undefined),
      questionSets.reload(undefined),
      exams.reload(undefined),
      assessors.reload(undefined),
      participants.reload(undefined),
    ])
  }, [companies.reload, projects.reload, questionSets.reload, exams.reload, participants.reload, assessors.reload])

  useRefreshEffect(() => {
    companies.reload(undefined)
    projects.reload(undefined)
    questionSets.reload(undefined)
    exams.reload(undefined)
    assessors.reload(undefined)
    participants.reload(undefined)
  })

  return <APIContext.Provider value={{
    companies: companies.resource ?? [],
    projects: projects.resource ?? [],
    questionSets: questionSets.resource ?? [],
    assessors: assessors.resource ?? [],
    participants: participants.resource ?? [],
    exams: exams.resource ?? [],
    loading: companies.loading || projects.loading || questionSets.loading || assessors.loading || exams.loading || participants.loading,
    refreshAll,
    refresh: {
      companies: async (silent: boolean) => {await companies.reload(undefined, silent)},
      projects: async (silent: boolean) => {await projects.reload(undefined, silent)},
      questionSets: async (silent: boolean) => {await questionSets.reload(undefined, silent)},
      exams: async (silent: boolean) => {await exams.reload(undefined, silent)},
      assessors: async (silent: boolean) => {await assessors.reload(undefined, silent)},
      participants: async (silent: boolean) => {await participants.reload(undefined, silent)},
    },
  }}>{children}</APIContext.Provider>
}

export function useApi() {
  return React.useContext(APIContext)
}

export function useFetchedResource<T, P = undefined>(get: (param: P) => Promise<T>) {
  const [resource, setResource] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchResource = useCallback(async (param: P, fetchSilently = false) => {
    if (!fetchSilently) {
      setLoading(true);
    }
    let fetchedResource = null
    try {
      fetchedResource = await get(param);
      setResource(fetchedResource);
    } catch (error) {
      // @ts-ignore
      setError(error);
    } finally {
      if (!fetchSilently) {
        setLoading(false);
      }
    }
    return fetchedResource
  }, []);

  return { resource, error, loading, reload: fetchResource };
}
