import React, {FC, useState} from "react";
import {Company} from "../api/dto";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Input} from "../components/form/Input";
import {useApiCall} from "../api/api";
import {useRefresh} from "../components/RefreshController";

export const RenameCompanyModal: FC<{company: Company, companies: Company[]}> = props => {
  const {updateCompany} = useApiCall()
  const refresh = useRefresh()
  const [name, setName] = useState(props.company.name)
  const sameNameCompanies = props.companies.filter(company => company.name === name && company.id !== props.company.id)
  const save = async () => {
    await updateCompany(props.company.id, name)
    refresh()
  }
  return <div>
    <Input type={'text'} label={'Naam'} value={name} onChange={setName} />
    {sameNameCompanies.length === 1 ? <div className={'bg-amber-100 mt-4 border-2 border-amber-200 text-amber-900 mb-4 text-sm rounded-lg px-4 py-3'}>
      <h2 className={"font-bold"}>Bedrijven samenvoegen</h2>
      Er is een ander bedrijf met deze naam, als je opslaat worden deze samengevoegd.
    </div> : <></>}
    {sameNameCompanies.length > 1 ? <div className={'bg-amber-100 mt-4 border-2 border-amber-200 text-amber-900 mb-4 text-sm rounded-lg px-4 py-3'}>
      <h2 className={"font-bold"}>Bedrijven samenvoegen</h2>
      Er zijn {sameNameCompanies.length} andere bedrijven met deze naam, als je opslaat worden deze samengevoegd.
    </div> : <></>}
    <ModalFooter text={'Opslaan'} onSubmit={save}/>
  </div>
}
