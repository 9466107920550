import React, {FC, useState} from "react";
import {Project} from "../api/dto";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Input} from "../components/form/Input";
import {useApiCall} from "../api/api";
import {useRefresh} from "../components/RefreshController";

export const RenameProjectModal: FC<{project: Project, projects: Project[]}> = props => {
  const {updateProject} = useApiCall()
  const refresh = useRefresh()
  const [name, setName] = useState(props.project.name)
  const sameNameProjects = props.projects.filter(project => project.name === name && project.id !== props.project.id && project.companyId === props.project.companyId)
  const save = async () => {
    await updateProject(props.project.companyId, props.project.id, name)
    refresh()
  }
  return <div>
    <Input type={'text'} label={'Naam'} value={name} onChange={setName} />
    {sameNameProjects.length === 1 ? <div className={'bg-amber-100 mt-4 border-2 border-amber-200 text-amber-900 mb-4 text-sm rounded-lg px-4 py-3'}>
      <h2 className={"font-bold"}>Projecten samenvoegen</h2>
      Er is een ander project binnen dit bedrijf met deze naam, als je opslaat worden deze samengevoegd.
    </div> : <></>}
    {sameNameProjects.length > 1 ? <div className={'bg-amber-100 mt-4 border-2 border-amber-200 text-amber-900 mb-4 text-sm rounded-lg px-4 py-3'}>
      <h2 className={"font-bold"}>Projecten samenvoegen</h2>
      Er zijn {sameNameProjects.length} andere projecten binnen dit bedrijf met deze naam, als je opslaat worden deze samengevoegd.
    </div> : <></>}
    <ModalFooter text={'Opslaan'} onSubmit={save}/>
  </div>
}
