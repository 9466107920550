import React, {FC, useState} from "react";
import {Company, Exam, ExamParticipant, Project} from "../api/dto";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Input} from "../components/form/Input";
import {useApiCall} from "../api/api";
import {useRefresh} from "../components/RefreshController";
import moment from "moment/moment";

interface ExportManagementDashboardModalProps {
  allCompanies: Company[]
  allProjects: Project[]
  allExams: Exam[]
  allParticipants: ExamParticipant[]
  company: Company
  onlyExams: Exam['id'][]
  onlyContractors: ExamParticipant['contractor'][]
  onlySubContractors: ExamParticipant['subContractor'][]
}
export const ExportManagementDashboardModal: FC<ExportManagementDashboardModalProps> = props => {
  const {exportSelectionAdminDashboardPdf} = useApiCall()
  const refresh = useRefresh()
  const examDays = Array.from(new Set(props.onlyExams.map(examId => moment(props.allExams.find(e => props.onlyExams.includes(e.id))?.examAt).format('YYYY-MM-DD'))))
  const [groupedContractors, setGroupedContractors] = useState<string[]>([])

  const exportAndDownload = async () => {
    const blob = await exportSelectionAdminDashboardPdf(props.onlyExams, Array.from(new Set(groupedContractors)), props.onlyContractors.length > 0 ? props.onlyContractors : null, props.onlySubContractors.length > 0 ? props.onlySubContractors : null);
    const file = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = file
    // const project = props.filteredParticipants[0].project
    // const company = props.filteredParticipants[0].company
    a.download = 'dashboard.pdf';//getOverallDataFileName(props.filteredParticipants, company, project)
    a.click()
    URL.revokeObjectURL(file)
  }

  return <div>
    <div className={'flex'}>
      <div className={'flex-1 border border-slate-200 rounded px-3 py-2'}>
        <table className={'w-full'}>
          <tbody>
            <tr><th className={'text-left font-medium'}>Test days</th><td className={'text-right'}>{examDays.length}</td></tr>
            <tr><th className={'text-left font-medium'}>Tests performed</th><td className={'text-right'}>{props.onlyExams.length}</td></tr>
            <tr><th className={'text-left font-medium'}>Contractors</th><td className={'text-right'}>{props.onlyContractors.length}</td></tr>
          </tbody>
        </table>
      </div>
      <div className={'border border-slate-200 rounded w-24 ml-3 bg-slate-100 overflow-hidden'}>
        {props.company.logo ? <img src={props.company.logo} alt={props.company.name} className={'h-full w-full object-cover'}/> : <></>}
      </div>
    </div>
    <div className={'border border-slate-200 rounded px-3 py-2 mt-3'}>
      <h2 className={'font-medium mb-1'}>Groepeer contractors</h2>
      <div className={'text-sm text-slate-600'}>Veel kleinere contractors? Groepeer de kleine onder de naam <strong>"Other"</strong>. Selecteer contractors om te groeperen.</div>
      <div className={'grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-2 mt-2'}>
        {props.onlyContractors.map((contractor, i) => <label key={i} className={`flex items-center h-10 px-2 border rounded cursor-pointer text-sm leading-tight ${groupedContractors.includes(contractor) ? 'bg-brand-50 border-brand-500' : 'border-slate-200'}`}>
          <input type={'checkbox'} className={'mr-2'} checked={groupedContractors.includes(contractor)} onChange={(e) => {
            if (e.target.checked) {
              setGroupedContractors([...groupedContractors, contractor])
            } else {
              setGroupedContractors(groupedContractors.filter(c => c !== contractor))
            }
          }} />
          <span>{contractor}</span>
        </label>)}
      </div>
    </div>
    <ModalFooter text={'Exporteren'} onSubmit={exportAndDownload}/>
  </div>
}
