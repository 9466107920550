import React, {FC, ReactNode} from "react";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";

interface CalloutProps {
  children: ReactNode
  icon?: IconDefinition
  color?: "primary"|"success"|"danger"|"warning"
}

export const Callout: FC<CalloutProps> = props => {
  const bgColor = classNames({
    "bg-brand-100": props.color === 'primary',
    "bg-green-100": props.color === 'success',
    "bg-red-100": props.color === 'danger',
    "bg-amber-100": props.color === 'warning',
    "bg-slate-100": props.color === undefined,
  })
  return <div className={`my-4 p-6 rounded-lg ${bgColor} flex items-center justify-start`}>
    {props.icon !== undefined && <FontAwesomeIcon icon={props.icon} className={"mr-4 opacity-75"} />}
    <div className={"flex-1"}>
      {props.children}
    </div>
  </div>
}